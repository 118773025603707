const find_ = require('lodash/find');

const getAppDefIdFromPackageNameWrapper =
  (codePackagesData) => (packageName) => {
    const packageDataForGivenName = find_(
      codePackagesData,
      ({ importName }) => importName === packageName,
    );

    return packageDataForGivenName ? packageDataForGivenName.appDefId : null;
  };

module.exports = {
  getAppDefIdFromPackageNameWrapper,
};
